@import './../../../../scss/theme-bootstrap';

.locate-interstitial-overlay {
  #cboxLoadedContent {
    background: rgba(0, 0, 0, 0.85);
    padding: 0;
    margin: 0 auto;
    @include breakpoint($medium-up) {
      padding: 0;
    }
  }
  #cboxWrapper {
    background: rgba(0, 0, 0, 0.85);
  }
  #cboxClose {
    @include breakpoint($medium-up) {
      color: $color-white;
    }
  }
  #cboxClose {
    #{$rdirection}: 15px;
    @include breakpoint($medium-up) {
      #{$rdirection}: 21px;
      top: 5px;
      &:before {
        font-size: 18px;
        line-height: 1.3;
        color: $color-black;
      }
    }
  }
}

.block-template-country-picker-v1 {
  display: none;
}

#colorbox.locate-interstitial-overlay {
  z-index: 10001;
}

#localeInterstitial {
  background: $color-white;
  margin: auto;
  text-align: center;
  .welcome-label {
    margin-bottom: 20px;
  }
  .initial-hide {
    display: none;
  }
  .sub_title {
    text-align: center;
  }
  li {
    display: block;
    float: none;
    text-align: center;
    font-size: 12px;
    text-transform: uppercase;
    @include breakpoint($medium-up) {
      margin: 0;
      padding: 0 0 5px 0;
    }
  }
  ul {
    padding: 0;
    text-align: center;
    &.country-list,
    &.country-list-offsite,
    &.language-list {
      padding: 0;
      list-style-type: none;
    }
    &.country-list-offsite {
      display: none;
    }
    &.language-list {
      li {
        padding: 0 0 5px 0;
        &.selected {
          font-weight: bold;
          label,
          .label {
            font-weight: bold;
          }
        }
      }
    }
    &.country-list {
      padding-bottom: 5px;
      input[type='radio'] ~ label,
      input[type='radio'] ~ .label {
        &:before {
          display: none;
        }
      }
    }
  }
  .country-link {
    border: none;
    &.selected {
      font-weight: bold;
      label,
      .label {
        font-weight: bold;
      }
    }
  }
  .inner-border {
    padding: 35px 20px 20px 20px;
    @include breakpoint($medium-up) {
      padding: 20px 20px 20px 20px;
    }
  }
  #chooseLocaleButton {
    margin: 20px 0 0 0;
    width: 100%;
    @include breakpoint($medium-up) {
      width: auto;
    }
  }
  .current-country {
    font-weight: bold;
  }
  .choose-country {
    margin-bottom: 20px;
    @include breakpoint($medium-up) {
      max-width: 550px;
      margin: 0 auto 20px;
    }
  }
}
